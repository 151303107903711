import { createApp } from 'vue'
import App from './App.vue'
import '@/style/index.scss'

const app = createApp(App)
//公共头部（请勿删除）
import Header from '@/components/header.vue'
app.component('Header', Header);
//vant
import vant from 'vant'
import 'vant/lib/index.css';
app.use(vant)
// 引入路由对象实例
import router from './router/index.js'
app.use(router)
//设置全局请求接口地址
// import config from './api/config'
// app.config.globalProperties.$hrefUrl = config.hrefUrl;
// app.prototype.$hrefUrl = config.hrefUrl;//赋值使用




app.mount('#app')