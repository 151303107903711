import { createRouter, createWebHistory } from "vue-router"

const routes = [
    { path: "/", name: "pubilc", component: () => import('@/views/login'), },
    { path: "/use", name: "use", component: () => import('@/views/use'), },
    { path: "/edit", name: "edit", component: () => import('@/views/edit'), },
    { path: "/index", name: "index", component: () => import('@/views/index'), },
    { path: "/listRecord", name: "listRecord", component: () => import('@/views/listRecord'), },
    { path: "/subcribe", name: "subcribe", component: () => import('@/views/subcribe'), },
	{ path: "/search", name: "search", component: () => import('@/views/search'), },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router
